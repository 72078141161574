<h1 class="title-header">
  Glasportale & Glasfassaden		</h1>

<div style="display:flex; flex-flow:row wrap; justify-content: space-evenly;">
  <div  class="custom-text">
      <blockquote><p>Ob schlicht &amp; elegant oder prunkvoll &amp; imposant – auf jeden Fall einmalig!</p></blockquote>
      <blockquote><p>Gläserne Fassaden bilden die sichtbar-unsichtbare Haut Ihres Gebäudes oder Ihres Geschäftes.</p></blockquote>
      <p>Ein Glasportal ist Teil der Architektur. Ihr Glasportal oder Ihre Glasfassade wird von unseren erfahrenen Spezialisten mit der notwendigen Metall- oder Grundkonstruktion individuell angepasst. Wir verwenden ausschließlich hochwertige Isoliergläser mit Wärme-, Schall- und Sonnenschutz!<br>
      </p>
  </div>
</div>


<app-multiple-carousel [articles]="articles"></app-multiple-carousel>
