<div style="padding-left: 10px">

  <h2 style="text-align: center;"><strong>ALLGEMEINEN GESCHÄFTSBEDINGUNGEN (AGB) DER GLASTECHNIK RIHA</strong></h2><p><br></p><ol><li><strong>GELTUNG </strong></li><li><strong>ANGEBOTE </strong></li><li><strong>PREIS </strong></li><li><strong>TECHNISCHE GESCHÄFTSBEDINGUNGEN </strong></li><li><strong>GARANTIEERKLÄRUNG FÜR ISOLIERGLAS </strong></li><li><strong>GEWÄHRLEISTUNG, UNTERSUCHUNGS- UND RÜGEPFLICHT</strong></li><li><strong>SCHADENERSATZ </strong></li><li><strong>PRODUKTHAFTUNG </strong></li><li><strong>EIGENTUMSVORBEHALT </strong></li><li><strong>LIEFERUNG / ÜBERNAHME </strong></li><li><strong>ZAHLUNGSBEDINGUNGEN </strong></li><li><strong>MAHN- UND INKASSOSPESEN </strong></li><li><strong>STORNO </strong></li><li><strong>AUFRECHNUNG </strong></li><li><strong>LEISTUNGSVERWEIGERUNG UND ZURÜCKBEHALTUNG </strong></li><li><strong>RECHTSWAHL, GERICHTSSTAND, ERFÜLLUNGSORT </strong></li><li><strong>SONSTIGES</strong></li></ol><p>

  <strong>1. GELTUNG </strong><p>Die Lieferungen, Leistungen und Angebote unseres Unternehmens, im folgenden
  Auftragnehmer genannt, erfolgen ausschließlich aufgrund dieser
  Geschäftsbedingungen.<br><br>
  Entgegenstehende oder von den Geschäftsbedingungen des Auftragnehmers
  abweichende Bedingungen des Kunden, im folgenden Auftraggeber genannt, werden
  nicht anerkannt, es sei denn, der Auftragnehmer hätte ausdrücklich ihrer Geltung
  zugestimmt. Vertragserfüllungshandlungen gelten insofern nicht als Zustimmung.<br><br>
  Der Auftraggeber stimmt zu, dass im Falle der Verwendung von AGB durch ihn im
  Zweifel von den Bedingungen des Auftragnehmers auszugehen ist, auch wenn die
  Bedingungen des Auftraggebers unwidersprochen blieben.<br><br>Sofern einzelne Bestimmungen dieser AGB ganz oder teilweise ungültig sein oder
  werden sollten, wird dadurch die Wirksamkeit der übrigen Teile dieser AGB nicht
  berührt.
  <p><br></p>

  <strong>2. ANGEBOTE </strong><p>Die Angebote des Auftragnehmers sind freibleibend. Ein Vertragsangebot eines
  Auftraggebers bedarf einer Auftragsbestätigung. Letztverbraucher sind an ihr
  Vertragsangebot zwei Wochen gebunden. Auch das Absenden der vom Auftraggeber
  bestellten Ware bewirkt den Vertragsabschluss.<br><br>
  Werden an den Auftragnehmer Angebote gerichtet, so ist der Anbietende eine
  angemessene, mindestens jedoch 8-tägige Frist ab Zugang des Angebotes daran
  gebunden. <p><br></p>


  <strong>3. PREIS </strong><p>Die Preise gelten, wenn nicht ausdrücklich Gegenteiliges vereinbart wurde, ab Betrieb
  ohne Verpackung, ohne Versicherung und Versandkosten, bei Konsumenten inklusive
  Mehrwertsteuer.<br><br>
  Die genannten oder vereinbarten Preise des Auftragnehmers entsprechen der aktuellen
  Kalkulationssituation.<br><br>
  Sollten sich die Lohnkosten aufgrund kollektivvertraglicher Regelungen in der Branche
  oder anderer, für die Kalkulation relevante Kostenstellen des Kostenvoranschlages oder
  zur Leistungserstellung notwendiger, von uns nicht beeinflussbarer Kosten wie jene für
  Materialien, Energie, Transporte, Fremdarbeiten, Finanzierung verändern, ist der
  Auftragnehmer berechtigt bzw. verpflichtet, die Preise entsprechend zu erhöhen oder zu
  ermäßigen.<br><br>
  Bei Konsumenten gilt dieses Preisanpassungsrecht erst nach Ablauf von 2 Monaten
  nach Vertragsabschluss, es sei denn, dieses Recht wurde ausdrücklich ausgehandelt.
  <p><br></p>


  <strong>4. TECHNISCHE GESCHÄFTSBEDINGUNGEN </strong><p>
  Die Errechnung der für die Preisermittlung relevanten Maße ergibt sich aus den
  handelsüblichen Gepflogenheiten. Für Verglasungen von Fenstern und Fensterwänden,
  Trennwänden, Dachverglasungen sowie Wandverkleidungen etc. aus Glas gelten die
  Bestimmungen aus den geltenden Normen und Verglasungsrichtlinien.<br><br>
  Lieferungen erfolgen in handelsüblicher Qualität. Die von den Lieferwerten
  beanspruchten Toleranzen hinsichtlich der Dicke, sonstiger Maße sowie der Fehler,
  Farb- und Strukturunterschiede usw. gelten auch vom Auftraggeber als genehmigt. Für
  Verbraucher gilt, dass der Unternehmer eine von ihm zu erbringenden Leistung einseitig
  ändern oder von ihr abweichen kann, wenn dem Verbraucher diese Änderung
  beziehungsweise Abweichung zumutbar ist, besonders weil sie geringfügig und sachlich
  gerechtfertigt ist, sofern dies mit dem Verbraucher im Einzelnen ausgehandelt wurde.
  Hingewiesen wird darauf, dass Unterschiede in Farbton und Struktur bei Flachglas
  produktionsbedingt sind. Sie können insbesondere bei Nachlieferungen und
  Reparaturen nicht ausgeschlossen werden und stellen daher keinen Mangel dar.
  <p><br></p>

  <strong>5. GARANTIEERKLÄRUNG FÜR ISOLIERGLAS </strong><p>
  Der Hersteller des Isolierglases garantiert für einen Zeitraum von 5 Jahren - gerechnet
  vom Zeitpunkt der Lieferung ab Werk des Herstellers - dafür, dass sich zwischen den
  Scheiben kein wie immer gearteter Beschlag bildet, der eine einwandfreie Durchsicht
  beeinträchtigt. Diese Garantie verpflichtet nur zum kostenlosen Ersatz der fehlerhaften
  Isolierglaselemente. Das Ausglasen schadhafter Isolierglaselemente sowie das
  Einglasen der Ersatzelemente gehen zu Lasten des Auftraggebers. Dadurch sind
  gesetzliche Gewährleistungsansprüche nicht eingeschränkt.<br><br>
  Der Auftragnehmer verpflichtet sich, die Verglasungsvorschriften der
  Isolierglaserzeugung einzuhalten und die Arbeiten gegen angemessenes Entgelt
  durchzuführen. Voraussetzung für oben stehende Garantieleistungen ist eine
  fachgerechte Wartung und Instandhaltung des Rahmens und des Dichtungsmaterials
  durch den Bauherrn bzw. Auftraggeber.
  <p><br></p>


  <strong>6. GEWÄHRLEISTUNG, UNTERSUCHUNGS- UND RÜGEPFLICHT </strong><p>
  Ist das KSchG nicht anwendbar, so erfüllt der Auftragnehmer
  Gewährleistungsansprüche des Kunden bei Vorliegen eines behebbaren Mangels nach
  seiner Wahl entweder durch Austausch, durch Reparatur innerhalb angemessener Frist
  oder durch Preisminderung. Schadenersatzansprüche des Kunden, die auf Behebung
  des Mangels zielen, können erst geltend gemacht werden, wenn der Auftragnehmer mit
  der Erfüllung der Gewährleistungsansprüche trotz Setzung einer angemessenen
  Nachfrist in Verzug geraten ist.<br><br>
  Ist das KSchG nicht anwendbar, so ist im Sinne der §§ 377 ff HGB die Ware nach der
  Ablieferung unverzüglich, längstens aber binnen 3 Werktagen zu untersuchen. Dabei
  festgestellte Mängel sind dem Auftragnehmer innerhalb von 3 Werktagen ab Lieferung
  unter Bekanntgabe von Art und Umfang des Mangels bekannt zu geben. Verdeckte
  Mängel sind unverzüglich, längsten aber binnen 3 Werktagen nach ihrer Entdeckung zu
  rügen.<br><br>
  Wird eine Mängelrüge außerhalb des Anwendungsbereiches des KSchG jedenfalls nicht
  oder nicht rechtzeitig erhoben, so gilt die Ware als genehmigt. Glasbruch ist von der
  Gewährleistung ausgeschlossen. Die Gewährleistung oder Garantie erlischt außerhalb
  des Anwendungsbereiches des KSchG mit Verarbeitung oder Veränderung des
  Liefergegenstandes durch den Auftraggeber oder durch Dritte.
  <p><br></p>


  <strong>7. SCHADENERSATZ </strong><p>
  Sämtliche Schadenersatzansprüche sind in Fällen leichter Fahrlässigkeit
  ausgeschlossen. Im Anwendungsbereich des KSchG gilt dies nicht für
  Personenschäden oder Schäden an zur Bearbeitung übernommenen Sachen.
  Das Vorliegen grober Fahrlässigkeit hat, sofern das KSchG nicht anwendbar ist, der
  Geschädigte zu beweisen.<br><br>
  Die absolute Verjährungsfrist für Schadenersatzansprüche beträgt außerhalb des
  KSchG 10 Jahre jeweils ab Gefahrenübergang, sofern der Geschädigte innerhalb von
  sechs Monaten nach Erkennbarkeit des Schadens seine Ansprüche gerichtlich geltend
  macht.<br><br>
  Die in diesen Geschäftsbedingungen enthaltenen oder sonst vereinbarten
  Bestimmungen über Schadenersatz gelten auch dann, wenn der
  Schadenersatzanspruch neben oder anstelle eines Gewährleistungsanspruches geltend
  gemacht wird.
  <p><br></p>

  <strong>8. PRODUKTHAFTUNG </strong><p>
  Regressforderungen im Sinne des § 12 Produkthaftungsgesetz sind ausgeschlossen, es
  sei denn, der Regressberechtigte weist nach, dass der Fehler in unserer Sphäre
  verursacht und zumindest grob fahrlässig verschuldet worden ist.
  <p><br></p>

  <strong>9. EIGENTUMSVORBEHALT </strong><p>
  Bis zur vollständigen Bezahlung des Kaufpreises einschl. aller Nebenforderungen bleibt
  die Ware - gleich in welchem Zustand - unbeschränktes Eigentum des Auftragnehmers,
  auch dann, wenn sie im Betrieb des Auftraggebers bearbeitet oder verwendet wird.<br><br>
  Scheck- und Wechselzahlungen haben keine schuldbefreiende Wirkung, sie werden nur
  zahlungshalber, nicht an Zahlungs Statt entgegengenommen. Der Auftraggeber darf die
  ihm gelieferte Ware bis zur vollständigen Bezahlung weder verpfänden noch zur
  Sicherheit übereignen. Im Falle einer vom Auftragnehmer genehmigten Veräußerung
  der im Vorbehaltseigentum stehenden Ware erklärt der Auftraggeber schon jetzt, seine
  Forderung gegen den Erwerber an den Auftragnehmer abzutreten, einen
  entsprechenden Buchvermerk samt Eintragung in die offene Postenliste vorzunehmen
  und den Auftragnehmer umgehend von der Veräußerung zu verständigen.
  <p><br></p>

  <strong>10. LIEFERUNG / ÜBERNAHME  </strong><p>
  Der Auftragnehmer ist berechtigt, die vereinbarten Termine und Lieferfristen um bis zu 2
  Wochen zu überschreiten. Erst nach Ablauf dieser Frist kann der Auftraggeber eine
  angemessene, mindestens 2 weitere Wochen umfassende Nachfrist setzen und gem. §
  918 ABGB vom Vertrag zurücktreten, wenn innerhalb dieser Nachfrist vom Auftragnehmer nicht erfüllt oder die Erfüllung angeboten wird. Der Auftragnehmer ist
  berechtigt, Teil- oder Vorlieferungen durchzuführen und zu verrechnen.<br><br>
  Zur Leistungsausführung ist der Auftragnehmer erst dann verpflichtet, wenn der
  Auftraggeber allen seinen Verpflichtungen, die zur Ausführung erforderlich sind,
  nachgekommen ist, insbesondere alle technischen und vertraglichen Einzelheiten,
  Vorarbeiten und Vorbereitungsmaßnahmen erfüllt hat.<br><br>
  Die Arbeiten sind grundsätzlich ab Fertigstellung zu übernehmen. Erfolgt keine formale
  Übernahme, gelten mangels berechtigter Einwände des Auftraggebers die Arbeiten
  binnen 3 Tagen ab Fertigstellung als übernommen, wenn dem Auftraggeber die
  Fertigstellung angezeigt wurde oder aufgrund der Umstände des Falles dem
  Auftraggeber bekannt in musste.<br><br>
  Nach Übernahme der Leistung im Sinne dieser Vereinbarung gehen alle Risken und die
  Kosten der Lagerung zu Lasten des Auftraggebers. Auch bei erfolgter Teillieferung geht
  das gesamte Risiko für diese auf den Auftraggeber über.
  <p><br></p>


  <strong>11. ZAHLUNGSBEDINGUNGEN </strong><p>
  Die Zahlungen sind entsprechend den vereinbarten Zahlungsbedingungen zu leisten.
  Sind keine gesonderten Zahlungsbedingungen ausgehandelt, ist der Rechnungsbetrag
  innerhalb von 30 Tagen ab Rechnungsdatum ohne jeden Abzug zu bezahlen.
  Skontoabzüge bedürfen einer gesonderten Vereinbarung. Im Falle des
  Zahlungsverzuges, auch mit Teilzahlungen, treten allfällige Skontovereinbarungen zur
  Gänze außer Kraft.<br><br>
  Zahlungen des Auftraggebers gelten erst mit dem Zeitpunkt des Eingangs auf unserem
  Geschäftskonto als geleistet.
  Bei Zahlungsverzug des Auftraggebers ist der Auftragnehmer berechtigt, nach seiner
  Wahl den Ersatz des tatsächlich entstandenen Schadens zu begehren oder
  Verzugszinsen in Höhe von acht Prozentpunkten über dem Basiszinssatz per anno zu
  verrechnen.<br><br>
  Im Verbrauchergeschäft liegt der Verzugszinssatz bei fünf Prozentpunkten über dem
  Basiszinssatz. Bei Kreditgeschäften mit Konsumenten belaufen sich die Verzugszinsen
  auf den für vertragsgemäße Zahlung vereinbarten Zinssatz zuzüglich 5 Prozentpunkte
  per anno.<br><br>
  Der Anspruch auf Mahn- und Inkassospesen bleibt insofern unberührt, besteht also
  darüber hinaus. (siehe folgender Punkt)
  <p><br></p>


  <strong>12. MAHN- UND INKASSOSPESEN </strong><p>
  Der Auftraggeber verpflichtet sich für den Fall des Verzuges, die dem Gläubiger
  entstehenden notwendigen Kosten zweckentsprechender außergerichtlicher
  Betreibungs- oder Einbringungsmaßnahmen insbesondere Mahn- und Inkassospesen
  zu ersetzen, soweit diese in einem angemessenen Verhältnis zur betriebenen
  Forderung stehen, wobei er sich im Speziellen verpflichtet, maximal die Vergütungen
  des eingeschalteten Inkassoinstitutes zu ersetzen, die sich aus der Verordnung des
  BMWA über die Höchstsätze des Inkassoinstituten gebührenden Vergütungen ergeben.
  Die Forderung aus den außergerichtlichen Betreibungskosten darf die Forderung aus
  dem Werkvertrag nicht übersteigen. Sofern der Gläubiger das Mahnwesen selbst
  betreibt, verpflichtet sich der Schuldner, pro erfolgter Mahnung einen Betrag von € 10,90
  sowie für die Evidenzhaltung des Schuldverhältnisses im Mahnwesen pro Halbjahr
  einen Betrag von € 3,63 zu bezahlen.<br><br>
  Darüber hinaus ist im Unternehmergeschäft (siehe oben) jeder weitere Schaden,
  insbesondere auch der Schaden, der dadurch entsteht, dass infolge Nichtzahlung
  entsprechend höhere Zinsen auf allfälligen Kreditkonten beim Auftragnehmer anfallen,
  unabhängig vom Verschulden am Zahlungsverzug zu ersetzen, wenn der
  Auftragnehmer nicht von seinem Recht auf acht Prozentpunkte über dem Basiszinssatz
  per anno als pauschalierte Vertragsstrafe Gebrauch macht (siehe oben).
  <p><br></p>


  <strong>13. STORNO </strong><p>
  Will der Auftraggeber den Vertrag stornieren, so hat der Auftragnehmer das Recht, eine
  Stornogebühr von 25 Prozent der Auftragssumme, die sofort fällig ist, zu verlangen,
  wenn der Auftragnehmer nicht auf Erfüllung besteht.
  <p><br></p>

  <strong>14. AUFRECHNUNG </strong><p>
  Der Auftragnehmer verzichtet auf die Möglichkeit der Aufrechnung. Dies gilt jedoch bei
  Verträgen, die unter das KSchG fallen, nicht für den Fall der Zahlungsunfähigkeit des
  Auftragnehmers sowie für Gegenforderungen, die in rechtlichem Zusammenhang mit
  der Forderung des Auftragnehmers stehen, gerichtlich festgestellt oder vom
  Auftragnehmer anerkannt sind.
  <p><br></p>

  <strong>15. LEISTUNGSVERWEIGERUNG UND ZURÜCKBEHALTUNG </strong><p>
  Außerhalb des Anwendungsbereiches des KSchG ist der Auftraggeber bei
  gerechtfertigter Reklamation außer in den Fällen der Rückabwicklung nicht zur
  Zurückhaltung des gesamten, sondern nur eines angemessenen Teiles des
  Bruttorechnungsbetrages, höchstens aber von 25 Prozent berechtigt.<br><br>
  Im Anwendungsbereich des KSchG kann der Auftraggeber seine Zahlung verweigern,
  wenn die Lieferung nicht vertragsgemäß erbracht wurde oder die Erbringung durch die
  schlechten Vermögensverhältnisse des Auftragnehmers, die dem Auftraggeber zur Zeit der Vertragsschließung nicht bekannt waren bzw. nicht bekannt sein mussten, gefährdet
  ist.
  <p><br></p>


  <strong>16. RECHTSWAHL, GERICHTSSTAND, ERFÜLLUNGSORT  </strong><p>
  Es gilt österreichisches materielles Recht. Die Anwendbarkeit des UN-Kaufrechtes wird
  ausdrücklich ausgeschlossen. Die Vertragssprache ist Deutsch. Die Vertragsparteien
  vereinbaren österreichische, inländische Gerichtsbarkeit. Handelt es sich nicht um ein
  Geschäft, das unter das KSchG fällt, ist zur Entscheidung aller aus diesem Vertrag
  entstehenden Streitigkeiten das am Sitz des Auftragnehmers sachlich zuständige
  Gericht ausschließlich örtlich zuständig.<br><br>
  Erfüllungsort ist der Sitz des Auftragnehmers.
  <p><br></p>


  <strong>17. SONSTIGES  </strong><p>
  Der Auftraggeber ist verpflichtet, dem Auftragnehmer Änderungen seiner Wohn- bzw.
  Geschäftsadresse sowie sein Geburtsdatum bekannt zu geben, solange das
  vertragsgegenständliche Rechtsgeschäft nicht beiderseitig vollständig erfüllt ist.<br><br>
  Wird die Mitteilung unterlassen, so gelten Erklärungen auch dann als zugegangen, falls
  sie an die zuletzt bekannt gegebene Adresse gesendet werden.<br><br>
  Pläne, Skizzen oder sonstige technische Unterlagen bleiben ebenso wie Muster,
  Kataloge, Prospekte, Abbildungen udgl. stets unser geistiges Eigentum; der
  Auftraggeber erhält daran keine wie immer gearteten Werknutzungs- oder
  Verwertungsrechte
  <p><br></p>

</div>
