<h1 class="title-header"> Glastüren & Glastrennwände </h1>

<div style="display:flex; flex-flow:row wrap; justify-content: space-evenly;">
  <div class="custom-text">
      <blockquote><p>Wir bauten die erste patentierte, elektronisch gesteuerte, einfache und mehrteilige Boden-Schiebe- &amp; Faltanlage der Welt!</p></blockquote>
      <p>Unsere Technologie ist seit Jahrzehnten in ganz Europa im Einsatz. Im Bereich <strong>Schiebeanlagen</strong> ist Glastechnik Riha als innovatives Unternehmen immer noch <strong>Vorreiter</strong>. Wir entwickeln Glastüren aller Art – von der Duschtüre über Ihre Ganzglas-Zimmertüre bis zu mehrteiligen Geschäftstürfronten.<br>
        Unser Angebot umfasst die einfache Glastür ebenso wie die <strong>elektronisch über verschiedene Sensoren gesteuerte Sicherheitstür</strong>. Alles wird bei uns entworfen, geplant, angefertigt und geliefert – selbstverständlich unter Berücksichtigung sämtlicher <strong>europäischer Normen</strong> und gesetzlicher Anforderungen.<br>
        Ihr Fußboden ist zu hoch, <strong>Ihre Standardtüren passen nicht</strong>, die Zarge ist zu alt? Oder Sie sind auf der Suche nach einem neuen Flair?</p>
      <blockquote><p>Wir planen die Tür nach Ihrem Wunschmaß!</p></blockquote>
      <p>Wir bieten <strong>Glastüren aus Sicherheitsglas</strong> in vielen Farben, Mustern und Motiven und auch Sonderformen an. Ihre Vorteile sind helle Räume durch einfallendes Licht und eine Vielzahl an Gestaltungsmöglichkeiten (z.B. durch Ätztechnik, farbiges Glas, Sandstrahltechnik etc.).</p>
      <blockquote><p>Glastüren unterteilen Räume, ohne zu trennen!</p></blockquote>
      <p>Kleine Zimmer wirken durch den Einsatz von Glastüren wesentlich größer und freundlicher. Räume erhalten durch den <strong>natürlichen Lichteinfall</strong> mehr Tageslicht und Helligkeit. Dazu bieten wir Türbeschläge jeglicher Art und Farbe, damit der Befestigung nichts mehr im Wege steht!<br>
        Wir konstruieren <strong>rahmenlose Glaswände aus Sicherheitsglas (ESG)</strong> für den exquisiten <strong>Office- oder Home-Bereich</strong> mit Höhen bis über fünf Meter. Bis zu drei Meter hohe Türen mit <strong>eingelassenen, hydraulischen Bodentürschliessern</strong> schließen sanft, gedämpft und zuverlässig.<br>
        Bei uns erhalten Sie integrierte elektronische oder händisch zu bedienende Schiebetüren, Schlösser, Türschienen, Faltwände, Schiebewände, Drehtüren, Pendeltüren, transparente Innenraumgestaltung und vieles andere mehr!</p>
      <p><strong>Ganzglasanlagen</strong> lassen sich für die unterschiedlichsten Anwendungsbereiche und Funktionen einsetzen. </p>
      <blockquote><p>Vom Entwurf &amp; Design bis zur Übergabe!</p></blockquote>
      <p>Unser Service ist umfassend. Gerne erstellen wir Ihnen ein Komplettangebot inkl. Lieferung, Montage, erforderlicher Unterkonstruktion, Material etc. an. Glastechnik Riha ist Ihre erste Anlaufstelle für ungewöhnliche und scheinbar unlösbare Ideen, Träume und Pläne. Wir stehen Ihnen mit unseren Spezialisten während der gesamten Planungs- und Bauphase zur Seite: vom Entwurf über die Finanzierung bis zur Produktion und Übergabe.<br>
      </p>
  </div>
</div>


<app-multiple-carousel [articles]="articles"></app-multiple-carousel>


