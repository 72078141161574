import { Component, OnInit } from '@angular/core';
import {SessionService} from '../../services/session.service';
import fliesen from '../../../assets/json/GlasfliesenWandverkleidungen.json';
import {Article} from '../../types';

@Component({
  selector: 'app-fliesen',
  templateUrl: './fliesen.component.html',
  styleUrls: ['./fliesen.component.scss']
})
export class FliesenComponent implements OnInit {

  articles: Article[] = [];

  constructor(private sessionService: SessionService) { }

  ngOnInit(): void {
    this.sessionService.homecarouselvisibile.next(false);
    this.articles = fliesen;
  }

}
