<ng-container *ngIf="!menuForm">
  <div class="carousel-wrapper">
    <p-carousel [value]="articles" styleClass="custom-carousel" [numVisible]="4" [numScroll]="4" [circular]="true" [responsiveOptions]="responsiveOptions" [page]="0">
      <ng-template let-article pTemplate="item">
        <div class="article-wrapper">
          <app-artikel [article]="article" ></app-artikel>
        </div>
      </ng-template>
    </p-carousel>
  </div>
</ng-container>


<ng-container *ngIf="menuForm">
  <div style="width: 100vw; margin: auto">
    <div class="menu-wrapper">
      <div class="menu-article-wrapper" *ngFor="let article of articles">
        <app-artikel [article]="article" ></app-artikel>
        <div class="title-part">
          <h5>
            <a class="detail btn btn-primary" [routerLink]="article.routerLink" title="Glasfenster &amp; Absturzsicherung">
              → Details
            </a>
          </h5>
        </div>
      </div>
    </div>
  </div>
</ng-container>
