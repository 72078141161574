import { Component, OnInit } from '@angular/core';
import {SessionService} from '../../services/session.service';
import {Article} from '../../types';

@Component({
  selector: 'app-referenzen',
  templateUrl: './referenzen.component.html',
  styleUrls: ['./referenzen.component.scss']
})
export class ReferenzenComponent implements OnInit {

  articles: Article[] = [];

  constructor(private sessionService: SessionService) { }

  ngOnInit(): void {
    this.sessionService.homecarouselvisibile.next(false);
  }

}
