<h1 class="title-header">
  Glasbau	</h1>

<div style="display:flex; flex-flow:row wrap; justify-content: space-evenly;">
  <div class="custom-text">
      <blockquote><p>Ein weiter Blick in die Ferne … </p></blockquote>
      <p>Durch seine unzähligen Gestaltungs- und Verarbeitungsmöglichkeiten zählt Glas in der modernen Architektur zu einem der attraktivsten Baustoffe!<br>
      </p>
  </div>
</div>


<app-multiple-carousel [articles]="articles"></app-multiple-carousel>
