
<div style="margin: 50px">
  <h3>Anfrage an Glastechnik Riha GmbH</h3>

  <form [formGroup]="formGroup">
    <div class="form-group">
      <label>Name*</label>
      <div class="form-row">
        <div class="col">
          <input type="text" class="form-control" maxlength="64" formControlName="vorname">
          <small class="form-text text-muted">Vorname</small>
        </div>
        <div class="col">
          <input type="text" class="form-control" maxlength="64" formControlName="nachname">
          <small class="form-text text-muted">Nachname</small>
        </div>
      </div>
      <small *ngIf="
      ( formGroup.controls['vorname'].hasError('required') || formGroup.controls['nachname'].hasError('required'))" class="error-text form-text text-muted">Name ist erforderlich</small>
    </div>


    <div class="form-group">
      <label>Adresse*</label>
      <input type="text" class="form-control" maxlength="64" formControlName="strasse">
      <small class="form-text text-muted">Straße Nr./Stg/Tür</small>
    </div>

    <div class="form-group">
      <div class="form-row">
        <div class="col">
          <input type="text" class="form-control" maxlength="64" formControlName="stadt">
          <small class="form-text text-muted">PLZ Stadt</small>
        </div>
        <div class="col">
          <input type="text" class="form-control" maxlength="64" formControlName="land">
          <small class="form-text text-muted">Land/Region</small>
        </div>
      </div>
      <small *ngIf="
      ( formGroup.controls['strasse'].hasError('required') || formGroup.controls['stadt'].hasError('required') || formGroup.controls['land'].hasError('required'))"  class="error-text form-text text-muted">Vollständige Adresse ist erforderlich</small>
    </div>


    <div class="form-group">
      <label>Email*</label>
      <input type="email" class="form-control" maxlength="64" formControlName="email">
      <small *ngIf="formGroup.controls['email'].hasError('required')" class="error-text form-text text-muted">Email ist erforderlich</small>
      <small *ngIf="formGroup.controls['email'].hasError('email')" class="error-text form-text text-muted">Email ist ungültig</small>
    </div>

    <div class="form-group">
      <label>Telefon*</label>
      <input type="text" class="form-control" maxlength="64" formControlName="telefon">
      <small *ngIf="formGroup.controls['telefon'].hasError('required')" class="error-text form-text text-muted">Telefon ist erforderlich</small>
    </div>

    <div class="form-group">
      <label>Ihr Anliegen*</label>
      <textarea type="text" class="form-control" maxlength="524" formControlName="anliegen"></textarea>
      <small *ngIf="formGroup.controls['anliegen'].hasError('required')" class="error-text form-text text-muted">Ihr Anliegen ist erforderlich</small>
    </div>

    <div><span class="highlight" style="background-color: transparent"><span class="colour" style="color: rgb(141, 148, 154)"><span class="font" style="font-family: &quot;Open Sans&quot;"><span class="size" style="font-size: 10px">Durch Klick auf den Button „Absenden“, erklären Sie sich einverstanden, dass wir Ihre Eingaben für die Beantwortung Ihrer Anfrage verwenden. Weiteres entnehmen Sie bitte der </span></span></span></span><b><a style="color: rgb(19, 84, 122); outline-color: invert; outline-style: none; outline-width: 0px; text-decoration: none" routerLink="/datenschutz" target="_blank" rel="noopener noreferrer"><span class="size" style="font-size: 10px">Datenschutzerklärung</span></a><span class="size" style="font-size: 10px">.</span></b><br></div>


    <button type="submit" class="btn btn-secondary" [disabled]="formGroup.invalid" style="margin: 20px 0;background: #306b67 !important;color: #F7D56C !important;" (click)="submitForm()">
      <span *ngIf="sending" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      Absenden</button>
  </form>

</div>
