<h1 class="title-header">
  Glasdach & Glasdachkonstruktion			</h1>

<div style="display:flex; flex-flow:row wrap; justify-content: space-evenly;">
  <div class="custom-text">
      <blockquote><p>Ästhetische Perfektion und Design, innovativ und modern – bis ins kleinste Detail durchdacht!</p></blockquote>
      <blockquote><p>Absoluter Lichteinfall, abgedunkelt, mit Photovoltaikzellen oder Bewegungsmeldern.</p></blockquote>
      <p><strong>Aufbau</strong><br>
        Unsere Glasdächer und ihre Beschläge sind je nach <strong>Bereich, Größe, örtliche Lage (Geothermie)</strong> und Anforderung unterschiedlich aufgebaut. Bei den <strong>Mustern</strong>, der <strong>Farbwahl</strong> und den <strong>Formen</strong> sind der Ausführung keine Grenzen gesetzt. Glasdächer mit <strong>absolutem Lichteinfall</strong> (Diamantglas) oder <strong>abgedunkelt, mit Photovoltaikzellen</strong> beleuchtet oder mit <strong>Bewegungsmeldern</strong> kombiniert – wir haben schon alles realisiert. Es wurden bereits Elemente in der Größe von 2000 x 6000 mm und mit einem Gewicht von 1.400 kg verarbeitet.</p>
      <p><strong>Edelstahlhalter</strong><br>
        Seit mehr als 20 Jahren erzeugen wir die speziellen, statisch geprüften <strong>Edelstahlhalter</strong> für Glasdächer jeder Art. <strong><a href="../../../assets/pdf/6_Glasdachkonstruktion_1.pdf">Glasdachkonstruktion (PDF)</a></strong></p>
      <p><strong>Unsere Erfahrung zählt</strong><br>
        Unsere Kunden sind in ganz Europa zuhause. Wir beliefern den internationalen Baukonzern ebenso wie den heimischen Glasereibetrieb. Und natürlich zählen viele Privathaushalte zu unseren zufriedenen Kunden. Bis heute hat Glastechnik Riha über 20.000 Garnituren ausgeliefert.</p>
      <p><strong>Umfassender Service</strong><br>
        Glastechnik Riha ist Ihre erste Anlaufstelle für ungewöhnliche und scheinbar unlösbare Ideen, Träume und Pläne. Wir stehen Ihnen mit unseren Spezialisten während der gesamten Planungs- und Bauphase zur Seite: vom Entwurf über die Finanzierung bis zur Produktion und Übergabe.</p>
  </div>
</div>

<app-multiple-carousel [articles]="articles"></app-multiple-carousel>
