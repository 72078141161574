<h1 class="title-header">
  Glasfenster & Absturzsicherung	</h1>

<div style="display:flex; flex-flow:row wrap; justify-content: space-evenly;">
  <div class="custom-text">
      <blockquote><p>Unsere Fenster sind geprüft und werden mit Höchstnoten bewertet.</p></blockquote>
      <p> Auch ein Nachrüsten Ihrer Fenster ist möglich. Sie ersparen sich Schutt, Schmutz, Kosten &amp; Aufregung. Ob alt oder neu, ob Fixrahmen oder mehrteilige Flügelanlage,&nbsp;ob Schallschutz oder Einbruchsschutz alles ist möglich! Sprechen Sie mit uns!</p>
      <p><strong>Hat Ihre Fensterscheibe einen Sprung? </strong></p><strong>
    </strong><p><strong>Gefällt Ihnen die Glasscheibe nicht mehr?</strong>&nbsp;</p>
      <p>Alles kein Problem! </p>
      <p>Wir bieten jede Art der Fenster-Reparaturverglasung vom Kittfenster bis zur Schaufensterscheibe an!</p>
  </div>
</div>


<app-multiple-carousel [articles]="articles"></app-multiple-carousel>
