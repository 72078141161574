import { Component, OnInit } from '@angular/core';
import {SessionService} from '../../services/session.service';

@Component({
  selector: 'app-vielen-dank',
  templateUrl: './vielen-dank.component.html',
  styleUrls: ['./vielen-dank.component.scss']
})
export class VielenDankComponent implements OnInit {

  constructor(private sessionService: SessionService) { }

  ngOnInit(): void {
    this.sessionService.homecarouselvisibile.next(false);
  }

}
