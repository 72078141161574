import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {SessionService} from '../../services/session.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {

  @ViewChild('homeslide') homeslide: any;

  constructor(private sessionService: SessionService) { }

  ngOnDestroy(): void {
        this.sessionService.homecarouselvisibile.next(false);
    }

  ngOnInit(): void {
    this.sessionService.homecarouselvisibile.next(true);
  }

}
