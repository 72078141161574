import { NgModule } from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AboutComponent } from './page-components/about/about.component';
import { BegehbareGlaeserComponent } from './page-components/begehbare-glaeser/begehbare-glaeser.component';
import { ContactsComponent } from './page-components/contacts/contacts.component';
import { DatenschutzComponent } from './page-components/datenschutz/datenschutz.component';
import { DuscheBadSanitaerComponent } from './page-components/dusche-bad-sanitaer/dusche-bad-sanitaer.component';
import { EinfuehrungsaktionGlasschiebetuerComponent } from './page-components/einfuehrungsaktion-glasschiebetuer/einfuehrungsaktion-glasschiebetuer.component';
import { FliesenComponent } from './page-components/fliesen/fliesen.component';
import { FormComponent } from './page-components/form/form.component';
import { GanzglasPendeltuerAnlagenComponent } from './page-components/ganzglas-pendeltuer-anlagen/ganzglas-pendeltuer-anlagen.component';
import { GlasdachGlasdachkonstruktionComponent } from './page-components/glasdach-glasdachkonstruktion/glasdach-glasdachkonstruktion.component';
import { GlasfassadenComponent } from './page-components/glasfassaden/glasfassaden.component';
import { GlasgelaenderAbsturzsicherungComponent } from './page-components/glasgelaender-absturzsicherung/glasgelaender-absturzsicherung.component';
import { HomeComponent } from './page-components/home/home.component';
import { ImpressumComponent } from './page-components/impressum/impressum.component';
import { KunstglasComponent } from './page-components/kunstglas/kunstglas.component';
import { PatentierteBeschlaegeComponent } from './page-components/patentierte-beschlaege/patentierte-beschlaege.component';
import { PortfolioGlasmoebelComponent } from './page-components/portfolio-glasmoebel/portfolio-glasmoebel.component';
import { PortfolioGlastuerenComponent } from './page-components/portfolio-glastueren/portfolio-glastueren.component';
import { PrivacyPolicyComponent } from './page-components/privacy-policy/privacy-policy.component';
import { ReferenzenComponent } from './page-components/referenzen/referenzen.component';
import { RihaGlasbauComponent } from './page-components/riha-glasbau/riha-glasbau.component';
import { Glasfenster2Component } from './page-components/glasfenster2/glasfenster2.component';
import { ArtikelComponent } from './components/artikel/artikel.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { ContainerComponent } from './components/container/container.component';
import {MDBBootstrapModule} from 'angular-bootstrap-md';
import { MultipleCarouselComponent } from './components/multiple-carousel/multiple-carousel.component';
import {CarouselModule} from 'primeng/carousel';
import { KontakttitleComponent } from './components/kontakttitle/kontakttitle.component';
import { LayoutComponent } from './page-components/layout/layout.component';
import {DialogModule} from 'primeng/dialog';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import { HashLocationStrategy, LocationStrategy} from '@angular/common';
import { VielenDankComponent } from './page-components/vielen-dank/vielen-dank.component';
import { AgbComponent } from './page-components/agb/agb.component';

@NgModule({
  declarations: [
    AppComponent,
    AboutComponent,
    BegehbareGlaeserComponent,
    ContactsComponent,
    DatenschutzComponent,
    DuscheBadSanitaerComponent,
    EinfuehrungsaktionGlasschiebetuerComponent,
    FliesenComponent,
    FormComponent,
    GanzglasPendeltuerAnlagenComponent,
    GlasdachGlasdachkonstruktionComponent,
    GlasfassadenComponent,
    GlasgelaenderAbsturzsicherungComponent,
    HomeComponent,
    ImpressumComponent,
    KunstglasComponent,
    PatentierteBeschlaegeComponent,
    PortfolioGlasmoebelComponent,
    PortfolioGlastuerenComponent,
    PrivacyPolicyComponent,
    ReferenzenComponent,
    RihaGlasbauComponent,
    Glasfenster2Component,
    ArtikelComponent,
    HeaderComponent,
    FooterComponent,
    ContainerComponent,
    MultipleCarouselComponent,
    KontakttitleComponent,
    LayoutComponent,
    VielenDankComponent,
    AgbComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MDBBootstrapModule.forRoot(),
    CarouselModule,
    DialogModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule
  ],
  providers: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
