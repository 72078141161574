import { Component, OnInit } from '@angular/core';
import {SessionService} from '../../services/session.service';
import {Article} from '../../types';
import glassfasaden from '../../../assets/json/GlasportaleGlasfassaden.json';

@Component({
  selector: 'app-glasfassaden',
  templateUrl: './glasfassaden.component.html',
  styleUrls: ['./glasfassaden.component.scss']
})
export class GlasfassadenComponent implements OnInit {

  articles: Article[] = [];

  constructor(private sessionService: SessionService) { }

  ngOnInit(): void {
    this.sessionService.homecarouselvisibile.next(false);
    this.articles = glassfasaden;
  }

}
