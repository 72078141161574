import {Component, Input, OnInit} from '@angular/core';
import {Article} from '../../types';
import {SessionService} from '../../services/session.service';
import {BreakpointObserver, BreakpointState} from '@angular/cdk/layout';

@Component({
  selector: 'app-artikel',
  templateUrl: './artikel.component.html',
  styleUrls: ['./artikel.component.scss']
})
export class ArtikelComponent implements OnInit {


  @Input() article: Article | undefined;
  isMobile = false;



  constructor(private sessionService: SessionService, private breakpointObserver: BreakpointObserver) { }

  ngOnInit(): void {

    this.breakpointObserver
      .observe(['(max-width: 600px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isMobile = true;
        } else {
          this.isMobile = false;
        }
      });
  }

  setSelectedArticle() {
    if (!this.isMobile) {
      this.sessionService.selectedArticle.next(this.article);
    }
  }

}
