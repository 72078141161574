<div class="footerborder"></div>
<div class="footerstyle custom-text">


  <div class="textwidget"><p><b>RIHA Glastechnik GmbH</b><br>
    Geschäftsführer: Sekmen Serkan<br>
    Palmgasse 10, A-1150 Wien<br>
    Email: <a href="emailto: office@glastechnik-riha.at">office@glastechnik-riha.at</a><br>
    Telefon: +43 (01) 892 35 29  Mobil: 0676 5772257
    <br>Fax: +43(01) 892 33 81<br>
    Bankverbindung Volksbank  AG Wien<br>
    Kto.Nr.: 415 534 05 006 BLZ 43000<br>
    IBAN: AT124300041553405006, Swift Code: VBOE AT WW<br>
    ATU 42322009, Fb. 131952y</p>

    <div id="footer-text" class="footer-text">
      <a routerLink="/home" title="Innovationen" class="site-name">RIHA Glastechnik GmbH</a> <span>© 2021 <em>•</em> <a routerLink="/privacy-policy" title="Datenschutzerklärung">Datenschutzerklärung</a></span>
    </div>
    <br>
  </div>

  <div></div>

  <div class="textwidget custom-html-widget">Mitglied der Wirtschaftskammer Wien <br>
    <a href="https://firmen.wko.at/riha-glastechnik-gesellschaft-mbh/wien/?firmaid=53876a4a-c3fb-46a1-9a49-2832dd74d587&standortid=1&standortname=wien%20%28bundesland%29&suchbegriff=glastechnik"  rel="noopener">
      Informationspflicht lt. §5 E-Commerce <br>  Gesetz, §14 Unternehmensgesetzbuch <br> bzw. §63 Gewerbeordnung und <br> Offenlegungspflicht lt. §25 Mediengesetz</a>
    <br><br>
    <a  routerLink="/datenschutz/" rel="noopener">Datenschutz</a><br>
    <a  href="/impressum/" rel="noopener">Impressum</a><br>
    <a  href="/agb/" rel="noopener">AGB</a>
  </div>

</div>
