<div class="custom-text">
  <article class="column span_2">
    <p class="high"><a class="high" style="color: #f7d56c !important;" routerLink="/form">→  FORMULAR Termin, Anfrage &amp; Angebot</a></p>
    <h5>Kontaktieren Sie uns!</h5>
    Telefon: 0043(0)18923529 Mobil: 0676 5772257<br>
    Email: <a href="mailto:office@glastechnik-riha.at">office@glastechnik-riha.at</a><br>
    <a href="http://glastechnik-riha.eu/contacts/"><img src="../../../assets/img/2018/12/riha-google-maps.png" alt="" width="150" class="alignleft"></a>
    <strong>Riha Glastechnik GmbH</strong><br> Palmgasse 10, 1150 Wien<br>
    Mo-Fr 7:30 - 17:00 durchgehend geöffnet!
  </article>
</div>
