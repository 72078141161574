<div class="slider-hello"><div style="text-transform: uppercase; font-size: 80px; font-weight: bold; padding-top: 50px; padding-bottom: 50px;">Riha</div>
  <h3>Ein Wiener Unternehmen, das sich durch weltweite Produktneuheiten &amp; Innovationen auszeichnet!</h3>
</div>


<div style="display:flex; flex-flow:row wrap; justify-content: space-between; padding-top: 50px;">
  <div class="custom-text">
    <p class="high">24 H GLASBRUCH NOTDIENST 0676 577 22 57</p>
    <div style="display: flex; flex-flow: row wrap; justify-content: space-between">
      <div>
        <img loading="lazy" class="alignleft wp-image-4909 size-thumbnail" src="../../../assets/img/2011/09/glasbruch-notdienst-150x150.jpg" alt="" width="150" height="150">
      </div>

      <div style="max-width: 160px;">
        Wir bieten Sofortreparatur bei Glas- &amp; Fensterschäden! Jahrelange Erfahrung &amp; individuelle Lösungen – rufen Sie an!<br>
        <div style="display: flex;column-gap: 10px;">
          <img loading="lazy" class="alignleft size-thumbnail wp-image-4932" src="../../../assets/img/2018/12/pdf.jpg" alt="" width="40" height="40" srcset="../../../assets/img/2018/12/pdf.jpg 223w, ../../../assets/img/2018/12/pdf-150x150.jpg 150w" sizes="(max-width: 40px) 100vw, 40px">
          <a class="biglink" href="../../../assets/pdf/Schadenanzeige-Glasbruch.pdf" target="_blank" rel="noopener noreferrer">          Schadensanzeige Glasbruch</a>
        </div>
      </div>
    </div>
  </div>

  <app-kontakttitle></app-kontakttitle>

  <div class="custom-text" style="max-width: 200px;">
    <h5>Prospekte</h5>
    <p>
    <div style="display: flex;column-gap: 10px;">
      <img loading="lazy" class="alignleft size-thumbnail wp-image-4932" src="../../../assets/img/2018/12/pdf.jpg" alt="" width="40" height="40" srcset="../../../assets/img/2018/12/pdf.jpg 223w, ../../../assets/img/2018/12/pdf-150x150.jpg 150w" sizes="(max-width: 40px) 100vw, 40px">
      <a class="biglink" target="_blank"  href="../../../assets/pdf/4.2_Glastuerbaender_11.pdf">Glasduschen &amp; Glastürbänder</a>
    </div>
    <a class="biglink" href="../../../assets/pdf/1.2_Tuerdruecker_1.pdf" target="_blank" rel="noopener noreferrer">Beschlagssysteme &amp; Türdrücker</a><br>
    <a class="biglink" href="../../../assets/pdf/3_Schiebetuersysteme_1.pdf" target="_blank" rel="noopener noreferrer">Schiebetürsysteme</a><br>
    <a class="biglink" href="../../../assets/pdf/4.1_Drehfluegel-Tuersystem_1.pdf" target="_blank" rel="noopener noreferrer">Drehflügel-Türsystem</a><br>
    <a class="biglink" href="../../../assets/pdf/2.2_Griffknoepfe_1.pdf" target="_blank" rel="noopener noreferrer">Griffknöpfe</a> <a class="biglink" href="../../../assets/pdf/2.1_Griffstangen_1.pdf" target="_blank" rel="noopener noreferrer">Griffstangen</a><br>
    <a class="biglink" href="../../../assets/pdf/1.1_Griffstangen_Sperr_Schliess_1.pdf" target="_blank" rel="noopener noreferrer">Griffstangen-Sperrsysteme</a><br>
    <a class="biglink" href="../../../assets/pdf/4.3_AnschlagPendel_1.pdf" target="_blank" rel="noopener noreferrer">Anschlag- &amp; Pendel</a>
  </div>

</div>
