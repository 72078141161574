import {Component, Input, OnInit} from '@angular/core';
import {Article} from '../../types';

@Component({
  selector: 'app-multiple-carousel',
  templateUrl: './multiple-carousel.component.html',
  styleUrls: ['./multiple-carousel.component.scss']
})
export class MultipleCarouselComponent implements OnInit {

  @Input() articles: Article[] = [];
  @Input() menuForm = false;
  responsiveOptions: any[] = [];

  constructor() { }

  ngOnInit(): void {
    this.responsiveOptions = [
      {
        breakpoint: '600px',
        numVisible: 1,
        numScroll: 1
      }
    ];
  }

}
