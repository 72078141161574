import { Component, OnInit } from '@angular/core';
import {SessionService} from '../../services/session.service';
import {Article} from '../../types';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {

  articles: Article[] = [];

  constructor(private sessionService: SessionService) { }

  ngOnInit(): void {
    this.sessionService.homecarouselvisibile.next(false);
  }

}
