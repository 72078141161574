<h1 class="title-header">
  Geländer aus Glas & Metall	</h1>

<div style="display:flex; flex-flow:row wrap; justify-content: space-evenly;">
  <div class="custom-text">
      <blockquote><p>Unsere Glasgeländer verbinden Ästhetik mit höchster Sicherheit.</p></blockquote>
      <p>Sie erfüllen alle gesetzlichen Sicherheitsnormen.<br>
        <strong>Der Vorteil für Sie:</strong> Aufwändige Instandhaltungsarbeiten sind nicht mehr nötig. Glasgeländer bieten Blickfreiheit, Lichteinfall, Stabilität und fast grenzenlose Freiheiten. Die Glaselemente können mit Beschlägen oder Edelstahlkonstruktionen in allen erdenklichen Varianten kombiniert werden – siehe auch&nbsp;<a href="http://glastechnik-riha.eu/patentierte-beschlaege/">patentierte Beschläge.</a>&nbsp;Getreu unserem Motto <strong>„Geht nicht – gibt’s nicht!“</strong> setzt Glastechnik Riha Ihr Wunschgeländer im Einklang mit den gesetzlichen Sicherheitsanforderungen und Normen für Sie um.<br>
        <strong>Ein großes Plus:</strong> Bei Außengeländern aus Edelstahl sind keine aufwändigen Instandhaltungsarbeiten wie Ausbessern, Abschleifen oder Streichen mehr notwendig.</p>
  </div>
</div>


<app-multiple-carousel [articles]="articles"></app-multiple-carousel>
