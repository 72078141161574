import { Component, OnInit } from '@angular/core';
import {SessionService} from '../../services/session.service';
import {Article} from '../../types';
import glasbau from '../../../assets/json/Glasbau.json';

@Component({
  selector: 'app-riha-glasbau',
  templateUrl: './riha-glasbau.component.html',
  styleUrls: ['./riha-glasbau.component.scss']
})
export class RihaGlasbauComponent implements OnInit {

  articles: Article[] = [];

  constructor(private sessionService: SessionService) { }

  ngOnInit(): void {
    this.sessionService.homecarouselvisibile.next(false);
    this.articles = glasbau;
  }

}
