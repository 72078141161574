<h1 class="title-header">
  Kunstverglasung	</h1>

<div style="display:flex; flex-flow:row wrap; justify-content: space-evenly;">
  <div class="custom-text">
      <ul>
        <li>Kunstverglasung für Türen &amp; Fenster,</li>
        <li>Bleiverglasung,</li>
        <li>exklusives Glasdesign in der Tiffany-Glaskunst-Technik &amp;</li>
        <li>Luxusduschen mit Kunstverglasung</li>
      </ul>
      <p><strong>Bleiverglasung</strong><br>
        Ein ganz besonderes Ambiente verleiht die Bleiverglasung mit hochwertigem Echtantikglas, welches mit Farbenvielfalt, Licht und natürlich individuellen Motiven kombiniert werden kann.</p>
      <p><strong>Inglasien für Mosaike aus Glas</strong><br>
        Mosaiken entstanden im Orient vor 5000 Jahren als Wand- oder Pfeilerschmuck. Seither erlebte die Mosaik-Kunst immer wieder großartige Blütezeiten in allen Kulturen.</p>
      <p><strong>Tiffany-Glaskunst-Technik</strong><br>
        Besonders geschätzt werden Tiffany-Leuchten, deren Schirme größtenteils aus farbigen Glasstücken bestehen, die zuvor in Kupferfolie eingefasst und dann miteinander verlötet werden. Diese Technik des Verbindens von Glasstücken (Tiffany-Glaskunst-Technik) machte Tiffany und seine Glaskreationen weltberühmt.Der amerikanische Glaskünstler Louis Comfort Tiffany (1848 – 1933) übte stärksten Einfluß auf die Entwicklung des Kunsthandwerks aus. Tiffany war mit seiner Glaskunst auf allen internationalen Ausstellungen der Jahrhundertwende vertreten. Das ungewöhnlich vielseitige Werk des besonders vom Jugendstil geprägten Künstlers besteht aus Glasfenster, Glasmosaiken, Vasen, Lampen, Keramik. Die komplizierten Herstellungsverfahren – besonders der Glaswaren – werden ebenso beschrieben wie auf die Persönlichkeit und eigenwillige Kunstauffassung von L.C. Tiffany eingegangen wird.<br>
      </p>
  </div>
</div>


<app-multiple-carousel [articles]="articles"></app-multiple-carousel>
