<h1 class="title-header">
  Patentierte Beschläge	</h1>

<div style="display:flex; flex-flow:row wrap; justify-content: space-evenly;">
  <div class="custom-text">
      <blockquote><p>Glastechnik Riha vereint in seinem Unternehmen profundes Know-how mit technologischen Innovationen.</p></blockquote>
      <p>Wir führen Beschläge in zahlreichen Formen und Farben. So wurden im Bereich Edelstahlbeschläge neuartige kompakte und modulare Sonderbeschläge für Glas in höchster Qualität entwickelt. Diese international patentierte Technologie ist einzigartig. Sie wird weltweit verwendet und ist für jede Art von Geschäftsportalen, für exklusive Hotel- oder Bürogebäudeeingänge sowie für Innenausbauten einsetzbar.</p>
      <ul>
        <li><a href="../../../assets/pdf/4.2_Glastuerbaender_11.pdf">Glasduschen &amp; Glastürbänder (PDF)</a></li>
        <li><a href="../../../assets/pdf/1.2_Tuerdruecker_1.pdf" target="_blank" rel="noopener noreferrer">Beschlagssysteme &amp; Türdrücker (PDF)</a></li>
        <li><a href="../../../assets/pdf/3_Schiebetuersysteme_1.pdf" target="_blank" rel="noopener noreferrer">Schiebetürsysteme (PDF)</a></li>
        <li><a href="../../../assets/pdf/4.1_Drehfluegel-Tuersystem_1.pdf" target="_blank" rel="noopener noreferrer">Drehflügel-Türsystem (PDF)</a></li>
        <li><a href="../../../assets/pdf/2.2_Griffknoepfe_1.pdf" target="_blank" rel="noopener noreferrer">Griffknöpfe (PDF)</a></li>
        <li><a href="../../../assets/pdf/2.1_Griffstangen_1.pdf" target="_blank" rel="noopener noreferrer">Griffstangen (PDF)</a></li>
        <li><a href="../../../assets/pdf/1.1_Griffstangen_Sperr_Schliess_1.pdf" target="_blank" rel="noopener noreferrer">Griffstangen-Sperrsysteme (PDF)</a></li>
        <li><a href="../../../assets/pdf/4.3_AnschlagPendel_1.pdf" target="_blank" rel="noopener noreferrer">Anschlag &amp; Pendelsysteme (PDF)</a></li>
      </ul>
  </div>
</div>


<app-multiple-carousel [articles]="articles"></app-multiple-carousel>
