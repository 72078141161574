import {ChangeDetectorRef, Component, HostListener, OnInit} from '@angular/core';
import {SessionService} from './services/session.service';
import {Article} from './types';
import home from '../assets/json/home.json';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'glastechnik';
  homecarouselvisibile = false;
  displayDialog = false;
  selectedArticle: Article | undefined;
  articles: Article[] = [];

  constructor(private sessionService: SessionService, private cdf: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.sessionService.homecarouselvisibile.subscribe( visibility => {
      this.homecarouselvisibile = visibility;
      this.cdf.detectChanges();
    });

    this.sessionService.selectedArticle.subscribe( article => {
      this.selectedArticle = article;
      this.cdf.detectChanges();
      this.displayDialog = true;
    });

    this.articles = home;
  }

}
