import { Component, OnInit } from '@angular/core';
import duschebadsanitaer from '../../../assets/json/DuscheBadWellness.json';
import {Article} from '../../types';
import {SessionService} from '../../services/session.service';

@Component({
  selector: 'app-dusche-bad-sanitaer',
  templateUrl: './dusche-bad-sanitaer.component.html',
  styleUrls: ['./dusche-bad-sanitaer.component.scss']
})
export class DuscheBadSanitaerComponent implements OnInit {

  articles: Article[] = []

  constructor(private sessionService: SessionService) { }

  ngOnInit(): void {
    this.sessionService.homecarouselvisibile.next(false);
    this.articles = duschebadsanitaer;
  }

}
