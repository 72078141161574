<div class="span12 custom-text">
  <div class="row" style="justify-content: center;">
    <div class="span12">
      <section class="title-section">
        <h1 class="title-header">
          Vielen Dank!	</h1>
      </section>
    </div>
  </div>
  <div id="content" class="row" style="justify-content: center;">
    <div class="span12">
      <div id="post-4691" class="page post-4691 type-page status-publish hentry">
        <p>Ihre Nachricht wurde an <a href="mailto:office@glastechnik-riha.at">office@glastechnik-riha.at</a> gesandt.<br>Wir werden diese in Kürze bearbeiten!</p>
        <h2>Ihre RIHA Glastechnik GmbH</h2>
        <div class="clear"></div>

      </div>
    </div>
  </div>
</div>
