  <div class="header">
    <div>
      <a routerLink="/home" class="logo_h logo_h__img"><img src="assets\img\2011\07\logo_short.jpg" alt="RIHA Glastechnik GmbH" title="Innovationen" style="width: 350px;"></a>
    </div>
    <div style="align-self: center;">
      <nav class="navbar navbar-expand navbar-light bg-light">
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav mr-auto mobilenavbar">
            <li class="nav-item active dropdown">
              <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                LEISTUNGEN
              </a>
              <div class="dropdown-menu" aria-labelledby="navbarDropdown" style="padding:0px">
                <a class="dropdown-item" routerLinkActive="routerActiveClass" routerLink="/dusche-bad-sanitaer">Dusche – Bad – Wellness</a>
                <a class="dropdown-item" routerLinkActive="routerActiveClass" routerLink="/portfolio-glastueren">Glastüren &amp; Glastrennwände</a>
                <a class="dropdown-item" routerLinkActive="routerActiveClass" routerLink="/fliesen">Glasfliesen &amp; Wandverkleidungen</a>
                <a class="dropdown-item" routerLinkActive="routerActiveClass" routerLink="/glasgelaender-absturzsicherung">Geländer aus Glas &amp; Metall</a>
                <a class="dropdown-item" routerLinkActive="routerActiveClass" routerLink="/glasfassaden">Glasportale &amp; Glasfassaden</a>
                <a class="dropdown-item" routerLinkActive="routerActiveClass" routerLink="/glasdach-glasdachkonstruktion">Glasdach &amp; Glasdachkonstruktion</a>
                <a class="dropdown-item" routerLinkActive="routerActiveClass" routerLink="/glasfenster-2">Glasfenster &amp; Absturzsicherung</a>
                <a class="dropdown-item" routerLinkActive="routerActiveClass" routerLink="/begehbare_glaeser">Begehbare und befahrbare Gläser</a>
                <a class="dropdown-item" routerLinkActive="routerActiveClass" routerLink="/portfolio-glasmoebel">Glasvitrinen &amp; Glasmöbel</a>
                <a class="dropdown-item" routerLinkActive="routerActiveClass" routerLink="/kunstglas">Kunstverglasung</a>
                <a class="dropdown-item" routerLinkActive="routerActiveClass" routerLink="/riha-glasbau">Glasbau</a>
                <a class="dropdown-item" routerLinkActive="routerActiveClass" routerLink="/patentierte-beschlaege">Patentierte Beschläge</a>
              </div>
            </li>
            <li class="nav-item active">
              <a class="nav-link" routerLink="/referenzen" role="button" aria-haspopup="true" aria-expanded="false">
                REFERENZEN
              </a>
            </li>

            <li class="nav-item active">
              <a class="nav-link" routerLink="/about" role="button" aria-haspopup="true" aria-expanded="false">
                ÜBER UNS
              </a>
            </li>

            <li class="nav-item active">
              <a class="nav-link" routerLink="/kontakt" role="button" aria-haspopup="true" aria-expanded="false">
                KONTAKT
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </div>

  </div>

  <div class="headerborder"></div>
