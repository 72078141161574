import { Component, OnInit } from '@angular/core';
import {SessionService} from '../../services/session.service';
import {Article} from '../../types';
import abstruzsicherung from '../../../assets/json/GlasfensterAbsturzsicherung.json';


@Component({
  selector: 'app-glasfenster2',
  templateUrl: './glasfenster2.component.html',
  styleUrls: ['./glasfenster2.component.scss']
})
export class Glasfenster2Component implements OnInit {

  articles: Article[] = [];

  constructor(private sessionService: SessionService) { }

  ngOnInit(): void {
    this.sessionService.homecarouselvisibile.next(false);
    this.articles = abstruzsicherung;
  }

}
