import { Component, OnInit } from '@angular/core';
import {SessionService} from '../../services/session.service';
import {Article} from '../../types';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
declare let Email: any;

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {

  articles: Article[] = [];
  formGroup: FormGroup;
  sending = false;

  constructor(private sessionService: SessionService, private formBuilder: FormBuilder, private router: Router) {
    this.formGroup = this.formBuilder.group( {
      vorname: ['', Validators.required],
      nachname: ['', Validators.required],
      strasse: ['', Validators.required],
      stadt: ['', Validators.required],
      land: ['', Validators.required],
      email: ['',  [Validators.required, Validators.email]],
      telefon: ['', Validators.required],
      anliegen: ['', Validators.required]
    })
  }

  ngOnInit(): void {
    this.sessionService.homecarouselvisibile.next(false);
  }

  submitForm() {

    this.formGroup.markAllAsTouched();
    if (this.formGroup.valid) {
      this.sending = true;
      Email.send({
        Host : "smtp.elasticemail.com",
        Username : "admin@glastechnik-riha.at",
        Password : "965322EA82297E70F106CF92A476B7C55C46",
        To : 'office@glastechnik-riha.at',
        From : "admin@glastechnik-riha.at",
        Subject : "Glastechnik Anliegen",
        Body : this.getMessage()
      }).then(
        (message: any) =>  {
          this.sending = false;
          this.router.navigateByUrl('vielen-dank')
        }
      );
    }
  }

  getMessage() {
    return 'Name:  ' + this.formGroup.controls['vorname'].value + ' ' + this.formGroup.controls['nachname'].value + '\n' +
           'Adresse: ' + this.formGroup.controls['strasse'].value + ' ' + this.formGroup.controls['stadt'].value + ' ' + this.formGroup.controls['land'].value + '\n' +
           'Email: ' + this.formGroup.controls['email'].value + '\n' +
           'Telefon: ' + this.formGroup.controls['telefon'].value + '\n' +
           'Anliegen: ' + this.formGroup.controls['anliegen'].value;
  }

}


// office@glastechnik-riha.at
