<h1 class="title-header"> Glasfliesen &amp; Wandverkleidungen	</h1>
<div style="display:flex; flex-flow:row wrap; justify-content: space-evenly;">
  <div class="custom-text">
    <blockquote><p>Emaillierte Glaswände</p></blockquote>
    <blockquote><p>Glaswandfliesen in sonder-weiß &amp; diamant</p></blockquote>
    <blockquote><p>integrierte Spiegelflächen</p></blockquote>
    <blockquote><p>Ofensicherheitswände</p></blockquote>
    <p><strong>Spiegelflächen</strong> bündig in <strong>Glaswandfliesen</strong> integriert – edel, elegant &amp; modern, Ausführung in <strong>sonder-weiß &amp; diamant.</strong><br>
      Moderne <strong>emaillierte Glaswände</strong> – punktgehaltene, emailierte <strong>Ofensicherheitswand</strong> </p>
  </div>
</div>

<app-multiple-carousel [articles]="articles"></app-multiple-carousel>
