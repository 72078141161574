<div  style="margin: 50px">
  <div class="row">
    <div class="span12" data-motopress-type="static" data-motopress-static-file="static/static-title.php">
        <h1 class="title-header">
          Haftungsausschluss und Urheberrecht	</h1>
    </div>
  </div>
  <div id="content" class="row">
    <div class="span12" data-motopress-type="loop" data-motopress-loop-file="loop/loop-page.php">
      <div id="post-169" class="page post-169 type-page status-publish hentry">
        <p>Die Glastechnik Riha GmbH stellt die auf der Webseite präsentierten Informationen unter größter Sorgfalt zusammen und aktualisiert die Inhalte laufend. Trotzdem übernimmt die Glastechnik Riha GmbH keinerlei Verantwortung für eventuell falsch wiedergegebene oder unvollständige Informationen (einschließlich Haftung gegenüber Dritten). Für die Inhalte der Webseiten Dritter, welche mittels Hyperlink aufgerufen werden können, übernimmt die Glastechnik Riha GmbH ebenfalls keinerlei Verantwortung.[/span6][span6]Der Inhalt der Webseiten kann jederzeit und ohne vorherige Ankündigungen geändert werden. Der gesamte Inhalt der Webseite ist urheberrechtlich geschützt. Alle Rechte sind vorbehalten. Jede Verwertung bedarf der ausdrücklichen Genehmigung durch die Glastechnik Riha GmbH und/oder des jeweiligen Inhabers der Urheberrechte.</p>
        <p>E-mail: <a href="mailto:web@glastechnik-riha.at">office@glastechnik-riha.at</a></p>
        <div class="clear"></div>
        <!--.pagination-->
      </div><!--post-->
    </div>
  </div>
</div>
