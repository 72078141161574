import {Component, Input, OnInit} from '@angular/core';
import {Article} from '../../types';

@Component({
  selector: 'app-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.scss']
})
export class ContainerComponent implements OnInit {

  @Input() articles: Article[] = [];

  constructor() { }

  ngOnInit(): void {
  }

}
