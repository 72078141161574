<div style="display: flex; flex-flow: row wrap">
  <div style="width: 100%">
    <app-header></app-header>
  </div>

  <div id="carouselExampleSlidesOnly" class="carousel slide" data-ride="carousel" data-interval="3000" style="width: 100%;" *ngIf="homecarouselvisibile">

    <div class="carousel-inner">
      <div class="carousel-item active">
        <img class="d-block w-100" src="assets/img/2021/pexels-thomas-windisch-454499.jpg" alt="First slide">
      </div>
      <div class="carousel-item">
        <img class="d-block w-100" src="assets/img/2021/pexels-expect-best-323780.jpg" alt="Second slide">
      </div>
      <div class="carousel-item">
        <img class="d-block w-100" src="assets/img/2021/pexels-yurii-hlei-1438036.jpg" alt="Third slide">
      </div>
    </div>
  </div>
  <div style="height: 100%; width:100%; max-width: 1200px;margin: auto">
    <router-outlet></router-outlet>
  </div>


  <app-multiple-carousel [articles]="articles" [menuForm]="true" *ngIf="homecarouselvisibile"></app-multiple-carousel>



  <div style="width: 100%">
    <app-footer></app-footer>
  </div>

</div>



<p-dialog [header]="this.selectedArticle.alt" [(visible)]="displayDialog" [modal]="true" [baseZIndex]="10000" [draggable]="false" [resizable]="false" *ngIf="selectedArticle" >
  <ng-container *ngIf="selectedArticle">
    <figure>
      <img #img id="myImg" [src]="selectedArticle.url" [alt]="selectedArticle.alt">
    </figure>
    <p  style="margin-bottom: 20px;"  [style]="{'max-width':img.width + 'px'}">{{selectedArticle.description}}</p>
  </ng-container>
</p-dialog>
