import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Article} from '../types';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  homecarouselvisibile = new BehaviorSubject(true);
  selectedArticle = new BehaviorSubject<Article | undefined>( undefined);

  constructor() { }
}
