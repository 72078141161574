<h1 class="title-header">
  Begehbare und befahrbare Gläser	</h1>

<div style="display:flex; flex-flow:row wrap; justify-content: space-evenly;">
  <div class="custom-text">
      <blockquote><p>Begehbares Glas – eine originelle und exklusive Nuance! Glasböden &amp; ein Glasdach können Räume sehr viel heller machen.</p></blockquote>
      <p><strong>Glastreppen, Glaspodeste oder Glaslaufstege</strong> sind Glaskonstruktionen, die durch Personenverkehr belastbar und bei Bedarf sogar rutschhemmend sind.</p>
      <p>Durch begehbares Glas in Treppen und Böden werden der <strong>natürliche Lichteinfluss</strong> und die <strong>Sonneneinstrahlung</strong> gesteuert. Wir kombinieren technisches Know-how und Sicherheitsanforderungen mit Ihren Designvorstellungen.</p>
      <p>Glastechnik Riha ist Ihre erste Anlaufstelle für <strong>ungewöhnliche und scheinbar unlösbare Ideen</strong>, Träume und Pläne. Wir stehen Ihnen mit unseren Spezialisten während der gesamten Planungs- und Bauphase zur Seite: Vom Entwurf über die Finanzierung bis zur Produktion und Übergabe.</p>
      <p>Sprechen Sie mit uns!</p>
  </div>
</div>

<app-multiple-carousel [articles]="articles"></app-multiple-carousel>
