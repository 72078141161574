<div class="motopress-wrapper content-holder clearfix" style="margin: 50px">
  <div class="container">
    <div class="row">
      <div class="span12" data-motopress-wrapper-file="page-fullwidth.php" data-motopress-wrapper-type="content">
        <div class="row">
          <div class="span12" data-motopress-type="static" data-motopress-static-file="static/static-title.php">
            <section class="title-section">
              <h1 class="title-header">
                Impressum	</h1>
            </section><!-- .title-section -->
          </div>
        </div>
        <div id="content" class="row">
          <div class="span12" data-motopress-type="loop" data-motopress-loop-file="loop/loop-page.php">
            <div id="post-2703" class="page post-2703 type-page status-publish hentry">
              <h2>Daten zum Unternehmen</h2>
              <p><b><span style="color: #818181; font-family: Arial; font-size: medium;">RIHA Glastechnik GmbH<br>
              </span></b> Geschäftsführer: Sekmen Serkan<br>
                Palmgasse 10, A-1150 Wien<br>
                Email: <a href="emailto: office@glastechnik-riha.at">office@glastechnik-riha.at</a><br>
                Telefon: +43 (01) 892 35 29 Mobil: 0676 5772257<br>
                Fax: +43(01) 892 33 81<br>
                Bankverbindung Volksbank AG Wien<br>
                Kto.Nr.: 415 534 05 006 BLZ 43000<br>
                IBAN: AT124300041553405006, Swift Code: VBOE AT WW<br>
                ATU 42322009, Fb. 131952y</p>
              <h2>Haftungsausschluss und Urheberrecht</h2>
              <p>Die Glastechnik Riha GmbH stellt die auf der Webseite präsentierten Informationen unter größter Sorgfalt zusammen und aktualisiert die Inhalte laufend. Trotzdem übernimmt die Glastechnik Riha GmbH keinerlei Verantwortung für eventuell falsch wiedergegebene oder unvollständige Informationen (einschließlich Haftung gegenüber Dritten). Die Informationen stellen wir Ihnen ohne jegliche Zusicherung oder Gewährleistung jedweder Art, sei sie ausdrücklich oder stillschweigend, zur Verfügung. Ausgeschlossen sind auch alle stillschweigenden Gewährleistungen betreffend die Handelsfähigkeit, die Eignung für bestimmte Zwecke oder den Nichtverstoß gegen Gesetze und Patente.</p>
              <p>Für die Inhalte der Webseiten Dritter, welche mittels Hyperlink aufgerufen werden können, übernimmt die Glastechnik Riha GmbH ebenfalls keinerlei Verantwortung.</p>
              <p>Der Inhalt der Webseiten kann jederzeit und ohne vorherige Ankündigungen geändert werden.</p>
              <p>Der gesamte Inhalt der Webseite ist urheberrechtlich geschützt. Alle Rechte sind vorbehalten. Jede Verwertung bedarf der ausdrücklichen Genehmigung durch die RIHA Glastechnik GmbH und/oder des jeweiligen Inhabers der Urheberrechte.</p>
              <h2>Webkonzept, Webentwicklung, Design &amp; Online-Redaktion</h2>
              <p>Image Glasbruch © Pexels Shutterstock.com</p>
              <div class="clear"></div>
              <!--.pagination-->
            </div><!--post-->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
