import { Component, OnInit } from '@angular/core';
import {SessionService} from '../../services/session.service';
import {Article} from '../../types';

@Component({
  selector: 'app-impressum',
  templateUrl: './impressum.component.html',
  styleUrls: ['./impressum.component.scss']
})
export class ImpressumComponent implements OnInit {

  articles: Article[] = [];

  constructor(private sessionService: SessionService) { }

  ngOnInit(): void {
    this.sessionService.homecarouselvisibile.next(false);
  }

}
