import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {AboutComponent} from './page-components/about/about.component';
import {BegehbareGlaeserComponent} from './page-components/begehbare-glaeser/begehbare-glaeser.component';
import {ContactsComponent} from './page-components/contacts/contacts.component';
import {DatenschutzComponent} from './page-components/datenschutz/datenschutz.component';
import {DuscheBadSanitaerComponent} from './page-components/dusche-bad-sanitaer/dusche-bad-sanitaer.component';
import {EinfuehrungsaktionGlasschiebetuerComponent} from './page-components/einfuehrungsaktion-glasschiebetuer/einfuehrungsaktion-glasschiebetuer.component';
import {FliesenComponent} from './page-components/fliesen/fliesen.component';
import {FormComponent} from './page-components/form/form.component';
import {GanzglasPendeltuerAnlagenComponent} from './page-components/ganzglas-pendeltuer-anlagen/ganzglas-pendeltuer-anlagen.component';
import {GlasfassadenComponent} from './page-components/glasfassaden/glasfassaden.component';
import {Glasfenster2Component} from './page-components/glasfenster2/glasfenster2.component';
import {GlasgelaenderAbsturzsicherungComponent} from './page-components/glasgelaender-absturzsicherung/glasgelaender-absturzsicherung.component';
import {HomeComponent} from './page-components/home/home.component';
import {ImpressumComponent} from './page-components/impressum/impressum.component';
import {KunstglasComponent} from './page-components/kunstglas/kunstglas.component';
import {PatentierteBeschlaegeComponent} from './page-components/patentierte-beschlaege/patentierte-beschlaege.component';
import {PortfolioGlasmoebelComponent} from './page-components/portfolio-glasmoebel/portfolio-glasmoebel.component';
import {PortfolioGlastuerenComponent} from './page-components/portfolio-glastueren/portfolio-glastueren.component';
import {PrivacyPolicyComponent} from './page-components/privacy-policy/privacy-policy.component';
import {ReferenzenComponent} from './page-components/referenzen/referenzen.component';
import {RihaGlasbauComponent} from './page-components/riha-glasbau/riha-glasbau.component';
import {GlasdachGlasdachkonstruktionComponent} from './page-components/glasdach-glasdachkonstruktion/glasdach-glasdachkonstruktion.component';
import {VielenDankComponent} from './page-components/vielen-dank/vielen-dank.component';
import {AgbComponent} from "./page-components/agb/agb.component";

const routes: Routes = [

  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'about',
    component: AboutComponent
  },
  {
    path: 'begehbare_glaeser',
    component: BegehbareGlaeserComponent
  },  {
    path: 'kontakt',
    component: ContactsComponent
  },
  {
    path: 'datenschutz',
    component: DatenschutzComponent
  },
  {
    path: 'dusche-bad-sanitaer',
    component: DuscheBadSanitaerComponent
  },
  {
    path: 'einfuehrungsaktion-glasschiebetuer',
    component: EinfuehrungsaktionGlasschiebetuerComponent
  },
  {
    path: 'fliesen',
    component: FliesenComponent
  },
  {
    path: 'form',
    component: FormComponent
  },
  {
    path: 'ganzglas-pendeltuer-anlagen',
    component: GanzglasPendeltuerAnlagenComponent
  },
  {
    path: 'glasdach-glasdachkonstruktion',
    component: GlasdachGlasdachkonstruktionComponent
  },
  {
    path: 'glasfassaden',
    component: GlasfassadenComponent
  },
  {
    path: 'glasfenster-2',
    component: Glasfenster2Component
  },
  {
    path: 'glasgelaender-absturzsicherung',
    component: GlasgelaenderAbsturzsicherungComponent
  },
  {
    path: 'impressum',
    component: ImpressumComponent
  },
  {
    path: 'kunstglas',
    component: KunstglasComponent
  },
  {
    path: 'patentierte-beschlaege',
    component: PatentierteBeschlaegeComponent
  },
  {
    path: 'portfolio-glasmoebel',
    component: PortfolioGlasmoebelComponent
  },
  {
    path: 'portfolio-glastueren',
    component: PortfolioGlastuerenComponent
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent
  },
  {
    path: 'referenzen',
    component: ReferenzenComponent
  },
  {
    path: 'riha-glasbau',
    component: RihaGlasbauComponent
  },
  {
    path: 'agb',
    component: AgbComponent
  },
  {
    path: 'vielen-dank',
    component: VielenDankComponent
  },

  // has to be on last line
  {
    path: '**',
    redirectTo: '/home'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
