import { Component, OnInit } from '@angular/core';
import {SessionService} from '../../services/session.service';
import {Article} from '../../types';
import beschlaege from '../../../assets/json/PatentierteBeschlaege.json';


@Component({
  selector: 'app-patentierte-beschlaege',
  templateUrl: './patentierte-beschlaege.component.html',
  styleUrls: ['./patentierte-beschlaege.component.scss']
})
export class PatentierteBeschlaegeComponent implements OnInit {

  articles: Article[] = [];

  constructor(private sessionService: SessionService) { }

  ngOnInit(): void {
    this.sessionService.homecarouselvisibile.next(false);
    this.articles = beschlaege;
  }

}
