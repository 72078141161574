import { Component, OnInit } from '@angular/core';
import {SessionService} from '../../services/session.service';
import {Article} from '../../types';
import glassgaelender from '../../../assets/json/GelaenderAusGlasMetall.json';


@Component({
  selector: 'app-glasgelaender-absturzsicherung',
  templateUrl: './glasgelaender-absturzsicherung.component.html',
  styleUrls: ['./glasgelaender-absturzsicherung.component.scss']
})
export class GlasgelaenderAbsturzsicherungComponent implements OnInit {

  articles: Article[] = [];

  constructor(private sessionService: SessionService) { }

  ngOnInit(): void {
    this.sessionService.homecarouselvisibile.next(false);
    this.articles = glassgaelender;
  }

}
