
<div style="display:flex; flex-flow:row wrap; justify-content: space-between; padding-top: 50px;">
  <div class="custom-text">
    <p class="high">24 H GLASBRUCH NOTDIENST 0676 577 22 57</p>
    <div style="display: flex; flex-flow: row wrap; justify-content: space-between">
      <div>
        <img loading="lazy" class="alignleft wp-image-4909 size-thumbnail" src="../../../assets/img/2011/09/glasbruch-notdienst-150x150.jpg" alt="" width="150" height="150">
      </div>

      <div style="max-width: 160px;">
        Wir bieten Sofortreparatur bei Glas- &amp; Fensterschäden! Jahrelange Erfahrung &amp; individuelle Lösungen – rufen Sie an!<br>
        <div style="display: flex;column-gap: 10px;">
          <img loading="lazy" class="alignleft size-thumbnail wp-image-4932" src="../../../assets/img/2018/12/pdf.jpg" alt="" width="40" height="40" srcset="../../../assets/img/2018/12/pdf.jpg 223w, ../../../assets/img/2018/12/pdf-150x150.jpg 150w" sizes="(max-width: 40px) 100vw, 40px">
          <a class="biglink" href="../../../assets/pdf/Schadenanzeige-Glasbruch.pdf" target="_blank" rel="noopener noreferrer">          Schadensanzeige Glasbruch</a>
        </div>
      </div>
    </div>
  </div>

  <app-kontakttitle></app-kontakttitle>

  <div class="custom-text" style="max-width: 200px;">
    <h5>Prospekte</h5>
    <p>
    <div style="display: flex;column-gap: 10px;">
      <img loading="lazy" class="alignleft size-thumbnail wp-image-4932" src="../../../assets/img/2018/12/pdf.jpg" alt="" width="40" height="40" srcset="../../../assets/img/2018/12/pdf.jpg 223w, ../../../assets/img/2018/12/pdf-150x150.jpg 150w" sizes="(max-width: 40px) 100vw, 40px">
      <a class="biglink" target="_blank"  href="../../../assets/pdf/4.2_Glastuerbaender_11.pdf">Glasduschen &amp; Glastürbänder</a>
    </div>
    <a class="biglink" href="../../../assets/pdf/1.2_Tuerdruecker_1.pdf" target="_blank" rel="noopener noreferrer">Beschlagssysteme &amp; Türdrücker</a><br>
    <a class="biglink" href="../../../assets/pdf/3_Schiebetuersysteme_1.pdf" target="_blank" rel="noopener noreferrer">Schiebetürsysteme</a><br>
    <a class="biglink" href="../../../assets/pdf/4.1_Drehfluegel-Tuersystem_1.pdf" target="_blank" rel="noopener noreferrer">Drehflügel-Türsystem</a><br>
    <a class="biglink" href="../../../assets/pdf/2.2_Griffknoepfe_1.pdf" target="_blank" rel="noopener noreferrer">Griffknöpfe</a> <a class="biglink" href="../../../assets/pdf/2.1_Griffstangen_1.pdf" target="_blank" rel="noopener noreferrer">Griffstangen</a><br>
    <a class="biglink" href="../../../assets/pdf/1.1_Griffstangen_Sperr_Schliess_1.pdf" target="_blank" rel="noopener noreferrer">Griffstangen-Sperrsysteme</a><br>
    <a class="biglink" href="../../../assets/pdf/4.3_AnschlagPendel_1.pdf" target="_blank" rel="noopener noreferrer">Anschlag- &amp; Pendel</a>
  </div>

</div>



<div   class="custom-text" >


  <div>
    <h2>Von der Vergangenheit</h2>
    <p>Die Firma Glastechnik Riha zählt heute zu den <strong>ältesten Handwerksbetrieben weltweit</strong>. Die Familiengeschichte lässt sich mehr als 600 Jahre zurückverfolgen. Erstmals wird das Unternehmen – damals als Mundblas- und Bleiglasbetrieb – gegen Ende des 14. Jahrhunderts erwähnt.</p>
    <p>Schon <strong>Heinrich Kreibich</strong>, der Urgroßvater des jetzigen Geschäftsführers Sekmen Serkan, <strong>lehrte</strong> das <strong>Glaserhandwerk</strong>. Er war maßgeblich an der <strong>Gründung der Wirtschaftskammer</strong> und am<strong> Ausbau der Lehre der verschiedenen Handwerkssparten</strong> beteiligt:</p>
    <ul>
      <li>Glaserhandwerk</li>
      <li>Schleiferei</li>
      <li>Glashandel</li>
      <li>Glasbläserei</li>
      <li>und alle angewandten Gewerbe</li>
    </ul>
    <p><strong>Josef Kreibich</strong>, Heinrich‘s Bruder, war im künstlerischen und kunstgewerblichen Glasaufbau in ganz Europa gefragt und unübertroffen. Seine Werke brachten ihm hohe <strong>Anerkennung und Auszeichnungen aus vielen Herrschaftshäusern</strong>. Den Höhepunkt seines Schaffens bildete die Teilnahme an den <strong>Weltausstellungen in Paris und Wien</strong>. Nach Ende des Ersten Weltkrieges gingen die Erzeugungsbetriebe in Polen, der Tschechoslowakei und der Ukraine verloren. Der Familie blieb nur das Stammhaus in Wien.</p>
    <p>1945 übernahm <strong>Margarete Riha</strong>, die Mutter Fritz Rihas, die Firma. Sie war die treibende Kraft des Unternehmens und maßgeblich für <strong>viele Wiener Glasbauten</strong> verantwortlich. Dazu gehören die <strong>Universität Wien</strong>, die <strong>Staatsoper</strong>, das <strong>Burgtheater</strong>, die <strong>Stadthalle Wien</strong>, die <strong>Technische Universität</strong> und vieles mehr.<br>
    </p>
  </div>


  <div>
      <h2>in die Zukunft</h2>
      <p>Schon in sehr jungen Jahren übernahm <strong>Fritz Riha</strong> 1974 die Firma und setzte mit großer Dynamik die Weiterentwicklung fort.</p>
      <ul>
        <li>1977-1994 wurden zahlreiche <strong>Großprojekte im In- und Ausland</strong> realisiert. International war das Unternehmen unter anderem in Ungarn und der ehemaligen Sowjetunion tätig. In Österreich wurden etliche Großprojekte wie die <strong>Messe Wien</strong>, der <strong>Flughafen Wien</strong>, die <strong>Staatsoper</strong> und mehrere <strong>Großhotelbauten</strong> verwirklicht. Eine Auslistung aller Projekte und Kunden finden Sie im Menüpunkt <a title="Referenzen" routerLink="/referenzen">Referenzen</a>.</li>
        <li>1980 begann sich Fritz Riha für die Produktion von <strong>Sicherheitsglas</strong> und die Erzeugung von eigenem <strong>Panzerglas</strong> zu engagieren.</li>
        <li>1990 wurde eine eigene <strong>Produktionsstätte für Edelstahl-Ganzglasbeschläge</strong> gegründet. Das <strong>Patent der integrierten Griff-Sperrschliessanlagen</strong> wird noch heute erfolgreich erzeugt und weltweit vertrieben.</li>
        <li>1995 wurde die <strong>Nova-Roll-Ganzglaschiebeanlage</strong> entwickelt und 1996 für mehrteilige und gebogene Anlagen erweitert.</li>
        <li>1997 wurde eine <strong>Handelsschiene für Glasbeschläge und Zubehör</strong> gegründet.</li>
        <li>2002 erfolgte die Produktion der <strong>weltweit ersten bodenbetriebenen, elektrischen Glasschiebeanlage</strong> (heute elektronisch gesteuert) sowie redundanter <strong>elektronischer Glasschiebeanlagen</strong> und deren Stahl-Unterkonstruktionen.</li>
        <li>2003 wurde das Unternehmen um eine kleine, aber feine <strong>Planungs- und Entwurfsabteilung</strong> erweitert. Diese Abteilung ist für den Bau von Portalen, Geschäftseingängen und anderen Glasanwendungen (unter Einbeziehung der jeweils erforderlichen Professionisten) zuständig.</li>
        <li>
          <div>Seit 2005 ist Fritz Riha Vorsitzender der Glasindustrie, Fachvertretung Wien der Wirtschaftskammer <a href="https://firmen.wko.at/riha-glastechnik-gesellschaft-mbh-glastechnik-riha/wien/?firmaid=53876a4a-c3fb-46a1-9a49-2832dd74d587&standortid=1&standortname=wien%20%28bundesland%29&suchbegriff=glastechnik" target="_blank" rel="noopener noreferrer"><strong>Fachvertretung Wien der Glasindustrie</strong></a></div>
        </li>
      </ul>
      <p>Unser Motto lautet:</p>
      <blockquote><p>GEHT NICHT – GIBT´S NICHT!</p></blockquote>
      <p>Ihre Glastechnik<br>
      </p>
  </div>




</div>

