import { Component, OnInit } from '@angular/core';
import {SessionService} from '../../services/session.service';
import {Article} from '../../types';
import glassdachkonstruktion from '../../../assets/json/GlasdachGlasdachkonstruktion.json';


@Component({
  selector: 'app-glasdach-glasdachkonstruktion',
  templateUrl: './glasdach-glasdachkonstruktion.component.html',
  styleUrls: ['./glasdach-glasdachkonstruktion.component.scss']
})
export class GlasdachGlasdachkonstruktionComponent implements OnInit {

  articles: Article[] = [];

  constructor(private sessionService: SessionService) { }

  ngOnInit(): void {
    this.sessionService.homecarouselvisibile.next(false);
    this.articles = glassdachkonstruktion;
  }

}
