import { Component, OnInit } from '@angular/core';
import {Article} from '../../types';
import portfolieglastueren from '../../../assets/json/GlastuerenGlastrennwaende.json';
import {SessionService} from '../../services/session.service';

@Component({
  selector: 'app-portfolio-glastueren',
  templateUrl: './portfolio-glastueren.component.html',
  styleUrls: ['./portfolio-glastueren.component.scss']
})
export class PortfolioGlastuerenComponent implements OnInit {

  articles: Article[] = []

  constructor(private sessionService: SessionService) { }

  ngOnInit(): void {
    this.sessionService.homecarouselvisibile.next(false);
    this.articles = portfolieglastueren;
  }


}
