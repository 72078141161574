<div class="custom-text">
  <blockquote><p>Wir würden uns freuen auch Sie zu unserem Kundenstamm zählen zu dürfen!</p></blockquote>
  <div  style="display:flex; flex-flow:  row wrap; justify-content: space-between" class="custom-text">
    <div>PALAIS COBURG – WIEN<br>
      BURGTHEATER – WIEN<br>
      LUGNER CITY – WIEN<br>
      PANHANS – SEMMERING<br>
      STRABAG – WIEN<br>
      PARKRING TOWER – WIEN<br>
      STAATSOPER – WIEN<br>
      STADTHALLE WIEN<br>
      KAUFHAUS Steffl – WIEN<br>
      ALBERTINA – WIEN<br>
      TECHNISCHE UNIVERSITÄT WIEN<br>
      FLUGHAFEN – WIEN<br>
      HOFBURG – WIEN<br>
      KLINIK- und ARZTPRAXEN<br>
      COCA-COLA – INTERNATIONAL
    </div>
    <div>
      MESSE NEUBAU – WIEN<br>
      PEEK &amp; CLOPPENBURG – WIEN<br>
      SONY CENTER – BERLIN<br>
      ARMANI – CAPRI &amp; MAILAND<br>
      NEW YORKER – PARIS<br>
      NEW YORKER – LONDON<br>
      STRÄHLE – STUTTGART<br>
      SKY BAR – WIEN<br>
      RIVERSIDE div. – WIEN<br>
      HOTEL IMPERIAL – WIEN<br>
      ERSTE BANK FILIALEN<br>
      ÖNORM-INSTITUT – WIEN<br>
      MESSE und AUSSTELLUNGSGLASBAUTEN in MAUTHAUSEN,<br>
      DORNBIRN, MÜNCHEN, BERLIN, PARIS, LONDON<br>
      und viele andere mehr
    </div>
  </div>
  <div class="clear"></div>
  <!--.pagination-->
</div>
