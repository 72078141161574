<h1 class="title-header">Dusche – Bad – Wellness	</h1>

<div style="display:flex; flex-flow:row wrap; justify-content: space-evenly;">
  <div class="custom-text">
    <blockquote class="blockquote">
      <p>Wasserabweisende Glasduschen, offene Duschabtrennungen, gläserne Waschtische, Glasböden oder Glaswände um die Badewanne</p>
    </blockquote>
    <p>Sie wollen einen Wasserfall mit Kaskaden und ein Wasserbecken aus Glas? Wir bieten Ihnen individuelle Planung und maßgeschneidertes Design – alles aus einer Hand. Für Ihr ganz persönliches Badezimmer können Sie eine Auswahl aus vielen Farben und Motiven treffen. Sprechen Sie mit uns!<br>
      <strong>Lotuseffekt:</strong> Glas mit selbstreinigenden Eigenschaften – mit einer Nanobeschichtung modifizierte Glasoberflächen werden mit hydrophoben (wasserabweisenden) und schmutzabweisenden Eigenschaften ausgestattet. Wir gestalten Ihr persönliches Wellness-Eldorado!<br>
      Einen gläsernen Waschtisch oder einen verglasten Boden, wasserabweisende Glasduschen oder offene Duschabtrennungen, Glaswände rund um die Badewanne, oder sogar Glasböden und Wasserfälle und eine Auswahl an Beschlägen die zu den größten Österreichs gehört<br>
    </p>
  </div>
</div>

<app-multiple-carousel [articles]="articles"></app-multiple-carousel>



