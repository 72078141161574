

<div style="margin: 10px">
  <iframe src="https://maps.google.com/maps?f=q&amp;source=s_q&amp;hl=de&amp;geocode=&amp;q=Palmgasse+10+1150+Wien+&amp;aq=&amp;sll=48.220685,16.38006&amp;sspn=0.382001,0.617294&amp;ie=UTF8&amp;hq=&amp;hnear=Palmgasse+10,+Rudolfsheim-F%C3%BCnfhaus+1150+Wien,+%C3%96sterreich&amp;t=m&amp;ll=48.194815,16.338043&amp;spn=0.017164,0.077248&amp;z=14&amp;output=embed" width="100%" height="300" frameborder="0" marginwidth="0" marginheight="0" scrolling="no"></iframe>
</div>

<div class="custom-text" style="display: flex; flex-flow: row wrap;justify-content: space-between;">
  <app-kontakttitle></app-kontakttitle>
  <div>
    <h3>Besuchen Sie uns einfach während unserer Öffnungszeiten!</h3>
    <p>Mo-Fr 7:30 – 17:00 durchgehend geöffnet!<br>
    <h3>Wir freuen uns auf Ihren Besuch!</h3>
  </div>
</div>

