<h1 class="title-header">
  Glasvitrinen & Glasmöbel	</h1>

<div style="display:flex; flex-flow:row wrap; justify-content: space-evenly;">
  <div class="custom-text">
      <blockquote><p><strong>Glasvitrinen</strong> im Geschäftslokal<strong> – Glastische</strong> im Wohnbereich<strong> – Glasregale </strong>im Badezimmer<strong> – Glasrückwände</strong> in der Küche</p></blockquote>
      <p>Unsere <strong>Designabteilung</strong> setzt mit viel Fantasie Ihre Wünsche in die Tat um! </p>
      <p>Ob dezent, kraftvoll oder kontrastreich – der Kreativität sind keine Grenzen gesetzt. Die fast fugenlose Montage erleichtert die Reinigung wesentlich.</p>
      <p>Durch die Zusammenarbeit mit <strong>namhaften Designern aus Italien, Frankreich und England</strong> wurden bereits viele exklusive Möbelstücke angefertigt. Wir produzieren nicht nur Einzelstücke, sondern auch ganze Systeme, etwa Bücherwände.</p>
  </div>
</div>


<app-multiple-carousel [articles]="articles"></app-multiple-carousel>
